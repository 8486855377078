.card-holder {
	display: flex;
}

.card-holder-image {
	width: 50px;
	height: 50px;
	flex-grow: 0;
	border-radius: 50%;
	background-color: #e9eaeb;
}

.card-holder-lines {
	height: 10px;
	border-radius: 8px;
	background-color: #e9eaeb;
}

.card-holder-lines-thick {
	height: 12px;
	margin: 20px 10px;
	background-color: #e9eaeb;
}

.card-holder-lines-single {
	height: 30px;
	margin: 20px 10px;
	background-color: #e9eaeb;
}

.card-holder-table {
	min-height: 300px;
	border: 1px solid #e5e7e9;
}

.card-holder-table-header {
	height: 40px;
	background-color: #eef0f4;
}

.animate {
	animation: shimmer 2s infinite linear;
	background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
	background-size: 1000px 100%;
}

@keyframes shimmer {
	0% {
		background-position: -1000px 0;
	}
	100% {
		background-position: 1000px 0;
	}
}

.card-line-view {
	flex-grow: 1;
	margin-left: 1.6rem;
}

.mTop6rem {
	margin-top: 0.6rem;
}

.w10 {
	width: 10%;
}

.w20 {
	width: 20%;
}

.w30 {
	width: 30%;
}

.w40 {
	width: 40%;
}

.w50 {
	width: 50%;
}

.w60 {
	width: 60%;
}

.w70 {
	width: 70%;
}

.w80 {
	width: 80%;
}

.w90 {
	width: 90%;
}

.w100 {
	width: 100%;
}
