@font-face {
	font-family: 'iconFont';
	src: url('../fonts/font-icon/icomoon.eot');
	src: url('../fonts/font-icon/icomoon.eot?6tbn37#iefix') format('embedded-opentype'),
		url('../fonts/font-icon/icomoon.ttf?6tbn37') format('truetype'),
		url('../fonts/font-icon/icomoon.woff?6tbn37') format('woff'),
		url('../fonts/font-icon/icomoon.svg?6tbn37#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
	font-family: 'iconFont' !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-icon-customer-support:before {
	content: "\e967";
	color: #fff;
}
.icon-Insulin_RBS_icon:before {
	content: '\e966';
}
.icon-abnormality:before {
	content: '\e961';
	color: #57668f;
}
.icon-lab:before {
	content: '\e962';
	color: #57668f;
}
.icon-vital:before {
	content: '\e963';
	color: #57668f;
}
.icon-clinic-management:before {
	content: '\e95b';
}
.icon-coach-management:before {
	content: '\e95c';
}
.icon-doctor-management:before {
	content: '\e95d';
}
.icon-health-card:before {
	content: '\e95e';
}
.icon-patient-invitation:before {
	content: '\e95f';
}
.icon-patient-management:before {
	content: '\e960';
}
.icon-share:before {
	content: '\e95a';
}
.icon-warning:before {
	content: '\e959';
}
.icon-tick-round1:before {
	content: '\e956';
}
.icon-warning1:before {
	content: '\e957';
}
.icon-close-round1:before {
	content: '\e958';
}
.icon-pdf:before {
	content: '\e955';
}
.icon-link:before {
	content: '\e952';
}
.icon-round-loading .path1:before {
	content: '\e953';
	color: rgb(205, 211, 227);
}
.icon-round-loading .path2:before {
	content: '\e954';
	margin-left: -1em;
	color: rgb(51, 70, 119);
}
.icon-add-1:before {
	content: '\e951';
}
.icon-Investigation:before {
	content: '\e94e';
}
.icon-tips:before {
	content: '\e950';
}
.icon-diet-apple:before {
	content: '\e94b';
}
.icon-Exercise:before {
	content: '\e94c';
}
.icon-Medication:before {
	content: '\e94d';
}
.icon-profile:before {
	content: '\e94f';
}
.icon-sort-lines:before {
	content: '\e94a';
}
.icon-sort-line:before {
	content: '\e948';
}
.icon-filter-funel:before {
	content: '\e949';
}
.icon-import:before {
	content: '\e947';
}
.icon-Star-Fill:before {
	content: '\e935';
}
.icon-Star-Line:before {
	content: '\e944';
}
.icon-up-arrow-bold:before {
	content: '\e936';
}
.icon-Medical-report:before {
	content: '\e937';
}
.icon-Oxygen-saturation:before {
	content: '\e938';
}
.icon-Respiratory-rate:before {
	content: '\e939';
}
.icon-Temperature:before {
	content: '\e93a';
}
.icon-View-Journal:before {
	content: '\e93b';
}
.icon-Vitals-Shirt:before {
	content: '\e93c';
}
.icon-Weight-Tracking:before {
	content: '\e93d';
}
.icon-Abnormality-reports:before {
	content: '\e93e';
}
.icon-Blood-pressure:before {
	content: '\e93f';
}
.icon-Dischareg:before {
	content: '\e940';
}
.icon-Discharge-summary:before {
	content: '\e941';
}
.icon-Documents:before {
	content: '\e942';
}
.icon-Heart-rate:before {
	content: '\e943';
}
.icon-text:before {
	content: '\e933';
}
.icon-clock:before {
	content: '\e934';
}
.icon-Breakfast:before {
	content: '\e92b';
}
.icon-Chicken:before {
	content: '\e92c';
}
.icon-Dinner:before {
	content: '\e92d';
}
.icon-Fish:before {
	content: '\e92e';
}
.icon-Fruits:before {
	content: '\e92f';
}
.icon-Lunch:before {
	content: '\e930';
}
.icon-Snacks:before {
	content: '\e931';
}
.icon-Tea:before {
	content: '\e932';
}
.icon-Move:before {
	content: '\e927';
}
.icon-Copy:before {
	content: '\e928';
}
.icon-dashboard:before {
	content: '\e929';
}
.icon-Delete:before {
	content: '\e92a';
}
.icon-icon-tick-single:before {
	content: '\e925';
}
.icon-add:before {
	content: '\e924';
}
.icon-back:before {
	content: '\e922';
}
.icon-next:before {
	content: '\e923';
}
.icon-retry:before {
	content: '\e921';
}
.icon-info-fill:before {
	content: '\e920';
}
.icon-Edit:before {
	content: '\e91d';
}
.icon-Logout:before {
	content: '\e91e';
}
.icon-Settings:before {
	content: '\e91f';
}
.icon-down-arrow:before {
	content: '\e900';
}
.icon-left-arrow:before {
	content: '\e901';
}
.icon-right-arrow:before {
	content: '\e902';
}
.icon-up-arrow:before {
	content: '\e903';
}
.icon-filter:before {
	content: '\e916';
}
.icon-Health-report:before {
	content: '\e917';
}
.icon-user-star:before {
	content: '\e918';
}
.icon-user:before {
	content: '\e919';
}
.icon-vault-heart:before {
	content: '\e91a';
}
.icon-vitals:before {
	content: '\e91b';
}
.icon-arrow-right-double:before {
	content: '\e904';
}
.icon-attachment:before {
	content: '\e905';
}
.icon-chat:before {
	content: '\e906';
}
.icon-close:before {
	content: '\e907';
}
.icon-image:before {
	content: '\e908';
}
.icon-info-error:before {
	content: '\e909';
}
.icon-invitation:before {
	content: '\e90a';
}
.icon-lhamberger:before {
	content: '\e90b';
}
.icon-message:before {
	content: '\e90c';
}
.icon-patient:before {
	content: '\e90d';
}
.icon-play:before {
	content: '\e90e';
}
.icon-down-solid:before {
	content: '\e945';
}
.icon-up-solid:before {
	content: '\e946';
}
.icon-plus-round:before {
	content: '\e90f';
}
.icon-close-round:before {
	content: '\e926';
}
.icon-record:before {
	content: '\e910';
}
.icon-search:before {
	content: '\e911';
}
.icon-send:before {
	content: '\e912';
}
.icon-three-dots-v:before {
	content: '\e913';
}
.icon-three-dots-h:before {
	content: '\e91c';
}
.icon-tick-double:before {
	content: '\e914';
}
.icon-valut:before {
	content: '\e915';
}
.icon-admin-controls:before {
	content: '\e964';
}
.icon-admin-doctor:before {
	content: '\e965';
}
.icon-icon-video:before {
	content: '\e951';
	font-size: 14px;
}
.icon-icon-photo:before {
	content: '\e965';
}
